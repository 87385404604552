import React from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import RelatedStories from "../../components/RelatedStories";

import iconQuote from "../../img/icon-quote.png";
import person from "../../img/ian-deason-hex.png";

const hero =
    "https://cms2.climacell.co/wp-content/uploads/2019/02/case-jetblue-hero.jpg";
const logo =
    "https://cms2.climacell.co/wp-content/uploads/2019/02/jetblue-logo-color.png";

export default props => (
    <Layout>
      <SEO
          title="JetBlue ケーススタディ| tomorrow.io"
          image={hero}
          pathname={props.location.pathname}
      />
      <section className="c-section-hero">
        <div className="row mx-0">
          <div
              className="col-lg-3 px-0"
              style={{
                minHeight: 600,
                background: `url(${hero}) no-repeat center / cover`,
              }}
          />
          <div className="col-lg-7 px-0 d-flex align-items-center">
            <div className="c-shifted-mob w-100 pb-4">
              <div className="d-flex align-items-center mb-5 pt-2 pt-lg-0">
                <img className="mr-3 mb-1" src={logo} alt="" />
                <span>ケーススタディ</span>
              </div>
              <h1 className="h-2 mb-4">
                JetBlueは、他のソリューションでは見逃してしまう気象予報を、tomorrow.io航空版を利用して確認します
              </h1>
              <p className="fs-lg">
              tomorrow.ioのソリューションを6か月間にわたり試した後、JetBlueは、上位10の全ての空港でソリューションを展開するという決定に至る、成果を確認しました。
              </p>
            </div>
          </div>
        </div>
        <div className="text-sideways d-none d-md-block">
          42°21'02.1" N 71°03'27.6" W
        </div>
      </section>

      <section className="c-section bg-navy text-white c-section-skew">
        <div className="container">
          <div className="row">
            <h2 className="col-md-3 h-3 mb-4">挑戦</h2>
            <div className="col-md-9 fs-lg">
              <div className="embed-responsive embed-responsive-16by9 mb-5">
                <iframe
                    className="embed-responsive-item"
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/bGGy4-2LIzM?rel=0&controls=0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="JetBlue Case Study"
                />
              </div>
              <p>
                1998年の創業以来、JetBlueは、顧客体験と安全性にコミットしている航空会社であるとの評価を得ています。JetBlueは、米国、カリブ諸国、ラテンアメリカで、毎日平均1000フライトを運行し、102都市で年間4千万人の乗客の搭乗があります。一般の航空会社同様に、JetBlueが直面していたのは悪天候の状況下で、どのように安全に、効率的に、運航するのかが、大きな挑戦でした。特に、北東の運航は、米国内で最も混雑している空域な為、難しい状況でした。不要な天候遅延や欠航など、乗客に影響を与える事象を引き起こす状況を招くことが、従来の気象予報ツールの情報をご認識することでよく発生していました。このような状況を打開するのに、JetBlueは、オペレーションに新しい画期的な気象ソリューションを導入することを継続的に続けており、近年では地上オペレーションの意思決定に役立つようなソリューションを重点的に探していました。そこでtomorrow.ioに、チームの手元にあるデータ量を最大限に活用し、安全と顧客経験を重要視した、もっとも情報に基づいた意思決定ができるようにしたい、と要望がありました。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section bg-case-jetblue pb-0">
        <div className="container">
          <div className="row">
            <h2 className="col-md-3 h-3 mb-4">tomorrow.ioのソリューション</h2>
            <div className="col-md-9 fs-lg">
              <p>
                2017年に、JetBlueはtomorrow.ioのtomorrow.io™航空版ソリューションを、ボストンのローガン空港（KBOS）にあるオペレーションセンターで、初めて利用し始めました。利用開始直後から、オペレーションチームは他の気象ソリューションで見たことのないデータを目の当たりにしました。プログラムの一部として、JetBlueは重大な事象が発生する前のEメール、ならびに気象状況が一定のラインを超える時点で送信されるEメールやテキストメッセージによる通知を含む、気象学コンサルサービスも受けました。
              </p>
              <p>
                このプログラムは、tomorrow.ioの気象予報士によるライブサポートも含み、サポートは乗客に影響を与える可能性のある、複雑な気象状況を正確に把握するのに頻繁に役立ちました。tomorrow.ioのソリューションを6か月間にわたり試した後、JetBlueは、上位10の全ての空港でソリューションを展開する事を決定するに至る、成果を確認しました。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 text-center">
              <img className="my-4" src={iconQuote} alt="" />
              <div className="fs-lg mb-4 lh-lg">
              tomorrow.ioが他の誰もが観測できなかった、地上レベルの気象システムを採用していると、個人的に証言できます。tomorrow.ioのツールを管理者のもとに導入して以来、オペレーションマネージャーや除氷担当リーダー、地上オペレーション担当マネージャーといった、アクセスを希望するリーダー達から、次々と要請を受けました。このツールは、日々空港リーダーたちが直面している決断しなければいけないプレッシャーをよく理解してデザインされています。JetBlueは、tomorrow.ioのソリューション活用を拡大していくことを予定しています。
              </div>
              <p className="fs-lg fw-800 mb-4">
                Ian Deason氏、Senior Vice President of Customer Experience、Jetblue
              </p>
              <img className="mb-4" src={person} width="100" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="c-section">
        <div className="container">
          <div className="row">
            <h2 className="col-md-3 h-3 mb-4">結果</h2>
            <div className="col-md-9 fs-lg">
              <p>
                <strong>安全性</strong>
                <br />
                何よりもまず、tomorrow.ioのソリューションにより、作業場や乗客の旅の安全性を向上することができます。tomorrow.ioの雷アラートを乗員と乗客の安全を守るために利用したり、tomorrow.ioの降水量と気温データをテイクオフのタイミング決定に活用したり、除氷プロトコルに利用する場合も、安全というのはいつでも第一に優先されます。
              </p>
              <p>
                <strong>意思決定要因の向上</strong>
                <br />
                JetBlueチームは、tomorrow.ioが情報を提供した際に他の気象ソリューションでは得られなかった内容を得るケースがよくありました。例として、2018年2月のある朝、どの気象予報会社もボストンのローガン空港上空における雪嵐は午前11時頃に止むと予報していた際、tomorrow.ioは午前8時頃であると予報しました。この情報により航空会社は、多大な損失ならびに乗客の信頼を失うことにもなり得ない欠航を回避できました。
              </p>
              <p>
                <strong>コスト削減</strong>
                <br />
                JetBlueは、荒れ模様の気候である季節（夏と冬）では、より良いオペレーションの意思決定により、上位10のハブ空港において、全体で月間$25K～$50Kが潜在的に節約できると予想します。tomorrow.ioのデータは、不要な欠航や遅延による損失、単独の目的だけの他の気象ソリューションに費やす必要がなくなり、コスト削減に役立ちます。2018年の第一四半期において、JetBlueはボストンで、天候における791の欠航がありました。tomorrow.ioは、フライトタイムの6時間以内において意思決定に影響を与える最善の役割を果たしました。これは潜在的に75％のコスト削減のみでなく、また乗客が突然の計画変更を余儀なくされる事象を回避できたことを意味します。
              </p>
              <p>
                <strong>JetBlueのスタッフによる信頼</strong>
                <br />
                tomorrow.ioはKBOSオペレーションチームにとって欠く事の出来ない存在になり、ツールの信頼度は2017年6月に初めて利用開始した当時から700%以上増加しました。tomorrow.ioのtomorrow.io™ 航空版インターフェースは現在全てのチームが確認できる、第一の気象ソリューションとしてオペレーション室のオーバーヘッドスクリーンに表示されています。また、JetBlueはネットワークのある10の大規模空港にtomorrow.ioの導入を拡大しました。
              </p>
              <p>
                <strong>顧客満足度</strong>
                <br />
                JetBlueは、乗客が気象状況のせいで滑走路やゲートで待たなければいけない時間を最小限にし、目的地への移動時間を有効利用できるよう、tomorrow.ioを頼りにしています。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section bg-navy text-white c-section-skew-top">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8 fs-lg">
              <h2 className="h-3 mb-5 text-center">
              tomorrow.ioのおかげで、JetBlueが経験したこと：
              </h2>
              <div className="d-md-flex justify-content-between mb-4">
                <div>主要空港で月ごとの潜在的な節約</div>
                <div>$25K-$50K</div>
              </div>
              <div className="d-md-flex justify-content-between mb-4">
                <div>tomorrow.io航空版の利用増加</div>
                <div>700%</div>
              </div>
              <div className="d-md-flex justify-content-between mb-4">
                <div>
                tomorrow.ioを利用する主要空港におけるJetBlueオペレーションチーム
                </div>
                <div>10</div>
              </div>
              <div className="text-center pt-5">
                <a className="btn btn-lg btn-primary" href="https://www.tomorrow.io/free-trial?bid=2">
                  弊社の気象専門家にビジネスについて相談
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section">
        <RelatedStories id="jetblue" />
      </section>
    </Layout>
);
